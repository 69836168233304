/* App.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.slide-container {
  position: relative;
  height: 500px;
  width: 100%;
  overflow: hidden;
}

.slide-image {
  position: absolute;
  left: 100%;
  height: 100%;
  width: 100%;
  transition: left 1.3s ease-in;
}

.slide-image:first-child {
  left: 0;
}

/* App.css */
.hero-text h1 {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.hero-text p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.btn-light {
  background-color: #fff; /* Adjust the button color as needed */
  color: #333; /* Adjust the button text color as needed */
}

.btn-light:hover {
  background-color: #e2e2e2; /* Adjust the button hover color as needed */
}

/* App.css */
.dark-overlay {
  background-color: rgba(0, 0, 0, 0.5); /* The last number is the opacity */
}

.navbar-dark.bg-dark {
  background-color: #000 !important; /* Default Bootstrap class for a dark navbar */
  transition: background-color 0.5s ease;
}



/* Add the rest of your styles here */